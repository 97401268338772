import { actionTypes } from '../actions';

const ErrorReducer = (state = [], action) => {
    switch (action.type) {
        case actionTypes.SET_ERROR_DETAILS:
            return [...state, action.payload];
        default:
            return state;
    }
};

export default ErrorReducer;
