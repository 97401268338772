export default function storage(type) {
    const store = type === 'session' ? window.sessionStorage : window.localStorage;
    const add = (key, value) => {
        if (Array.isArray(value) || isJSON(value)) store.setItem(key, JSON.stringify(value));
        else store.setItem(key, value);
    };
    const get = (key) => {
        const x = store.getItem(key);
        if (!x) return null;
        else if (isJSON(x)) return JSON.parse(x);
        else return x;
    };
    const remove = (key) => store.removeItem(key);
    const clear = () => store.clear();
    return { add, get, remove, clear };
}

const isJSON = (x) => {
    try {
        JSON.parse(x);
    } catch (e) {
        return false;
    }
    return true;
};
