export default function getSearchParams(param) {
    const href = new URL(window.location.href);
    const querySearchParams = href.searchParams.get(param);
    const hashQuerySearchParams = getHashSearchParams(param);
    return querySearchParams || hashQuerySearchParams || undefined;
}
function getHashSearchParams(param) {
    const href = new URL(window.location.href);
    const h = new URLSearchParams(href.hash.split('?')[1]).get(param);
    return h;
}
