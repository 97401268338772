import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import useResize from '../../hooks/useResize';
import useGetVideos from '../../hooks/useGetVideos';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import Banner from './Banner';
import classNames from 'classnames';

export default function BannerSlider({ slides }) {
    const { width } = useResize();
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [loaded, setLoad] = React.useState(false);
    const { rtl } = useGetPageConfig();
    const { getCategoryDetails } = useGetVideos();
    const [sliderRef, instanceRef] = useKeenSlider(
        {
            loop: true,
            rtl: rtl,
            slideChanged(slider) {
                setCurrentSlide(slider.track.details.rel);
            },
            created() {
                setLoad(true);
            },
        },
        [
            (slider) => {
                let timeout;
                let mouseOver = false;
                function clearNextTimeout() {
                    clearTimeout(timeout);
                }
                function nextTimeout() {
                    clearTimeout(timeout);
                    if (mouseOver) return;
                    timeout = setTimeout(() => {
                        slider.next();
                    }, 2000);
                }
                slider.on('created', () => {
                    slider.container.addEventListener('mouseover', () => {
                        mouseOver = true;
                        clearNextTimeout();
                    });
                    slider.container.addEventListener('mouseout', () => {
                        mouseOver = false;
                        nextTimeout();
                    });
                    nextTimeout();
                });
                slider.on('dragStarted', clearNextTimeout);
                slider.on('animationEnded', nextTimeout);
                slider.on('updated', nextTimeout);
            },
        ]
    );
    const categoryDetailsMapped = getCategoryDetails().reduce((agg, item) => {
        return { ...agg, [item.categoryName]: item.categoryId };
    }, {});
    const bannerLg = slides.map((i) => i.bannerLg);
    const bannerSm = slides.map((i) => i.bannerSm);
    const bannerNames = slides.map((i) => i.name);
    const bannerUrls = bannerNames.map((i) => {
        if (!categoryDetailsMapped[i]) return `/home`;
        else return `/category/video/${categoryDetailsMapped[i]}`;
    });

    return (
        <div className="banner-slider-wrapper">
            {width > 576 ? (
                <div ref={sliderRef} className="keen-slider">
                    {bannerLg.map((item, idx) => (
                        <Banner className="keen-slider__slide" src={item} key={idx} url={bannerUrls[idx]} />
                    ))}
                </div>
            ) : (
                <div ref={sliderRef} className="keen-slider">
                    {bannerSm.map((item, idx) => (
                        <Banner className="keen-slider__slide" src={item} key={idx} url={bannerUrls[idx]} />
                    ))}
                </div>
            )}
            {loaded && instanceRef.current && (
                <div className="dots">
                    {[...Array(instanceRef.current.track.details.slides.length).keys()].map((idx) => {
                        return (
                            <button
                                key={idx}
                                onClick={() => {
                                    if (instanceRef.current) instanceRef.current.moveToIdx(idx);
                                }}
                                className={classNames('dot', { active: currentSlide === idx })}
                            ></button>
                        );
                    })}
                </div>
            )}
        </div>
    );
}
