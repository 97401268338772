import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAccessDetails, setErrorDetails, setUserDetails } from '../redux/actions';
import storage from '../utils/storage';
import getAccessMgmtUrl from '../utils/getAccessMgmtUrl';
import makeApiRequest from '../utils/makeApiRequest';
import { sendOnAuthFailE, sendOnAuthSuccessE } from '../analytics/data-collection';

const useAuth = () => {
    const [status, setStatus] = useState('idle');
    const [error, setError] = useState('');
    const dispatch = useDispatch();

    const execute = useCallback(async () => {
        setStatus(() => 'loading');
        setError(() => '');
        const _localStorage = storage('local');
        const _sessionStorage = storage('session');

        const { type: authType, url: accessMgmtHref, value: authValue } = getAccessMgmtUrl({});
        if (!accessMgmtHref) {
            dispatch(setErrorDetails('access-error: ' + 'no_param_found'));
            setStatus(() => 'failure');
            setError(() => 'server_error');
            return;
        }
        try {
            const accessMgmtResp = await makeApiRequest({
                url: accessMgmtHref,
                method: 'GET',
            });
            if (!accessMgmtResp.success) {
                const err = accessMgmtResp.error_reason;
                dispatch(setErrorDetails('access-error: ' + err));
                setStatus(() => 'failure');
                setError(() => err);
                sendOnAuthFailE({
                    type: authType,
                    uid: authType === 'uid' || authType === 'rid' ? authValue : undefined,
                    msisdn: authType === 'msisdn' ? authValue : undefined,
                    err: err,
                });
                return;
            }
            const { token: uid, msisdn } = accessMgmtResp.payload;
            dispatch(setAccessDetails(accessMgmtResp.payload));
            _localStorage.add('uid', uid);
            _localStorage.add('rid', accessMgmtResp.payload.rockmanId);
            _localStorage.add('msisdn', accessMgmtResp.payload.msisdn);
            sendOnAuthSuccessE({
                type: authType,
                uid: uid,
                msisdn: msisdn,
            });

            const userResp = await makeApiRequest({
                url: new URL(window.location.origin + '/api/user'),
                method: 'POST',
                body: {
                    accessToken: uid,
                    msisdn,
                    product: 'movio',
                },
            });
            if (!userResp.success) {
                dispatch(setErrorDetails('user-error: ' + userResp.error));
                setStatus(() => 'failure');
                setError(() => 'server_error');
            } else {
                const { authorization, ...rest } = userResp.payload;
                _sessionStorage.add('authorization', authorization);
                dispatch(setUserDetails(rest));
                setStatus(() => 'success');
            }
        } catch (err) {
            dispatch(setErrorDetails('access-error: ' + 'server_error'));
            setStatus(() => 'failure');
            setError(() => 'server_error');
            sendOnAuthFailE({
                type: 'uid',
                err: 'server_error',
            });
        }
    }, [dispatch]);

    useEffect(() => {
        execute();
    }, [execute]);

    return { status, error };
};

export default useAuth;
