import React from 'react';
import { useNavigate } from 'react-router-dom';
import useSearch from '../../hooks/useSearch';
import useTranslate from '../../hooks/useTranslate';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import Header from '../../components/Header/Header';
import BannerSlider from '../../components/Slider/BannerSlider';
import VideoCard from '../../components/VideoCard/VideoCard';
import Slider from '../../components/Slider/Slider';
import getBanners from '../../utils/getBanners';
import classNames from 'classnames';
import useGetMovies from '../../hooks/useMovies';
import useGetSearchVideos from '../../hooks/useGetSearchVideos';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';

export default function Movies() {
    const t = useTranslate();
    const { movies, getCategoryDetails } = useGetMovies();
    const { searchTerm, ...searchProps } = useSearch();
    const categories = getCategoryDetails();
    const searchVideos = useGetSearchVideos(searchTerm, movies);
    const { rtl } = useGetPageConfig();

    return (
        <>
            <Header searchTerm={searchTerm} {...searchProps} />
            <div className={classNames('home-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })} dir={rtl ? 'rtl' : 'ltr'}>
                <div className="home-page-banners">
                    <BannerSlider slides={getBanners()} />
                </div>
                <div className="home-page-controls">
                    <div className="title">{t('all_categories')}</div>
                </div>
                <div className="home-page-contents">
                    {searchTerm ? (
                        <MoviesList videos={searchVideos} categories={categories} />
                    ) : (
                        <>
                            <MoviesList videos={movies} categories={categories} />{' '}
                        </>
                    )}
                </div>
            </div>
        </>
    );
}

const MoviesList = ({ videos, categories }) => {
    const t = useTranslate();
    const navigate = useNavigate();
    return (
        <>
            {categories.map((c) => {
                return (
                    <div key={c.categoryId} className="category-wrapper">
                        <div className="category-head">
                            <div className="title">{t(c.categoryName, {}, c.categoryName)}</div>
                            <button onClick={() => navigate('/category/movie/' + c.categoryId)}>
                                <span>{t('view_all')}</span>
                            </button>
                        </div>
                        <div className="category-videos">
                            <Slider
                                slides={videos
                                    .filter((x) => x.categoryId === c.categoryId)
                                    .map((item) => (
                                        <VideoCard
                                            key={item.videoId}
                                            id={item.videoId}
                                            type={item.itemType}
                                            title={item.videoTitle}
                                            imageURL={item.videoPreview}
                                            onClick={() => navigate('/play/movie/' + item.videoId)}
                                        />
                                    ))}
                            />
                        </div>
                    </div>
                );
            })}
        </>
    );
};
