import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { config } from '../constants/domainConfig';
import { setMovieFavourite, setMovieHistory, setMovies, setVideoFavourite, setVideoHistory, setVideos } from '../redux/actions';
import makeApiRequest from '../utils/makeApiRequest';

const useInit = () => {
    const [status, setStatus] = useState('idle');
    const dispatch = useDispatch();
    const token = useSelector((state) => state.user.token);

    const execute = useCallback(async () => {
        const authorization = sessionStorage.getItem('authorization');
        setStatus('loading');
        const [contents, history, favorites, movies] = await Promise.allSettled([
            makeApiRequest({
                url: new URL(window.location.origin + `/api/videos/v2?lang=${config.lang}&subdomain=${config.subdomain}`).href,
                method: 'GET',
                headers: { authorization },
            }),
            makeApiRequest({
                url: new URL(window.location.origin + '/api/videos/history/get?token=' + token).href,
                method: 'GET',
                headers: { authorization },
            }),
            makeApiRequest({
                url: new URL(window.location.origin + '/api/videos/fav/get?token=' + token).href,
                method: 'GET',
                headers: { authorization },
            }),
            makeApiRequest({
                url: new URL(window.location.origin + '/api/videos/movies').href,
                method: 'GET',
                headers: { authorization },
            }),
        ]);
        if (contents.status === 'fulfilled' && contents.value.success) {
            dispatch(setVideos(contents.value.payload));
            if (history.status === 'fulfilled' && history.value.success) {
                dispatch(setVideoHistory(history.value.payload));
                dispatch(setMovieHistory(history.value.payload));
            }
            if (favorites.status === 'fulfilled' && favorites.value.success) {
                dispatch(setVideoFavourite(favorites.value.payload));
                dispatch(setMovieFavourite(favorites.value.payload));
            }
            if (movies.status === 'fulfilled' && movies.value.success) dispatch(setMovies(movies.value.payload));
            setStatus('success');
        } else {
            setStatus('failure');
        }
    }, [dispatch, token]);

    useEffect(() => {
        execute();
    }, [execute]);

    return { status };
};

export default useInit;
