import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';

const PrivacyPolicyLink = ({ lang = 'en', text, className }) => {
    const t = useTranslate();
    const title = text ? text : t('privacy_policy');
    switch (lang) {
        default:
            return (
                <Link to="/privacy-policy" target="_blank" className={classNames({ [className]: className !== undefined })}>
                    {title}
                </Link>
            );
    }
};

export default PrivacyPolicyLink;
