import React from 'react';
import useTranslate from '../../hooks/useTranslate';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import Header from '../../components/Header/Header';
import classNames from 'classnames';
import ProtectedLink from '../../utils/ProtectedLink';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';

export default function Home() {
    const t = useTranslate();
    const { rtl } = useGetPageConfig();
    return (
        <>
            <Header />
            <div className={classNames('welcome-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })} dir={rtl ? 'rtl' : 'ltr'}>
                <div className="welcome-page-title">{t('select_movie_or_video')}</div>
                <div className="welcome-page-buttons">
                    <ProtectedLink to="/home/movie">{t('movie')}</ProtectedLink>
                    <ProtectedLink to="/home/video">{t('video')}</ProtectedLink>
                </div>
            </div>
        </>
    );
}
