import { actionTypes } from '../actions';

const initState = {
    videos: [],
    videoFavorites: [],
    videoHistory: [],
};

const VideoReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_VIDEOS:
            return { ...state, videos: action.payload };
        case actionTypes.SET_VIDEOS_FAVORITE:
            return { ...state, videoFavorites: action.payload.videos };
        case actionTypes.ADD_FAVOURITE_VIDEO:
            return { ...state, videoFavorites: [...state.videoFavorites, action.payload] };
        case actionTypes.REMOVE_FAVOURITE_VIDEO:
            return { ...state, videoFavorites: state.videoFavorites.filter((i) => i !== action.payload) };
        case actionTypes.SET_VIDEOS_HISTORY:
            return { ...state, videoHistory: action.payload.videos };
        case actionTypes.ADD_TO_VIDEO_HISTORY: {
            const t = state.videoHistory.filter((i) => i.videoId !== action.payload.videoId);
            return { ...state, videoHistory: [...t, action.payload] };
        }
        case actionTypes.CLEAR_VIDEO_HISTORY: {
            return { ...state, videoHistory: [] };
        }
        default:
            return state;
    }
};

export default VideoReducer;
