const getUnsubLink = (subdomain) => {
    switch (subdomain) {
        default:
            return `http://n.mobiotv.com/unsubscribe/es`;
    }
};

const mkUnsubLink = (subdomain, arg) => {
    const unsubLink = new URL(getUnsubLink(subdomain));
    Object.keys(arg).forEach((x) => unsubLink.searchParams.append(x, arg[x]));
    return unsubLink.href;
};

export { mkUnsubLink };
