import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { IntlProvider } from 'react-intl';
import { Provider } from 'react-redux';
import { CookiesProvider } from 'react-cookie';
import Pages from './pages';
import store from './redux/store';
import getMessages from './translations';
import { AlertContainer } from './components/AlertContainer/AlertContainer';
import useGetConfig from './hooks/useGetPageConfig';
import { config } from './constants/domainConfig';

const App = () => {
    React.useEffect(() => {
        const h = document.querySelector('html');
        h.setAttribute('lang', config.lang);
        if (['ar'].indexOf(config.lang) >= 0) h.setAttribute('dir', 'rtl');
    }, []);
    return (
        <Provider store={store}>
            <RouterHOC />
        </Provider>
    );
};

const IntlHOC = () => {
    const { lang } = useGetConfig();
    return (
        <IntlProvider defaultLocale="en" locale={lang} messages={getMessages(lang)}>
            <CookiesProvider>
                <AlertContainer>
                    <Pages />
                </AlertContainer>
            </CookiesProvider>
        </IntlProvider>
    );
};

const RouterHOC = () => {
    return (
        <BrowserRouter>
            <IntlHOC />
        </BrowserRouter>
    );
};

export default App;
