import { formatDistance } from 'date-fns';
import { useSelector } from 'react-redux';
import mergeByCommonKeys from '../utils/mergeByCommonKey';

export default function useGetVideos() {
    const original = useSelector((state) => state.video.videos);
    const history = useSelector((state) => state.video.videoHistory);
    const favorites = useSelector((state) => state.video.videoFavorites);

    const transformed = original
        .reduce((agg, item) => {
            const i = {
                categoryId: item.categoryId,
                categoryName: item.categoryName,
            };
            const v = item.videos.map((x) => ({ ...x, ...i }));
            return [...agg, v];
        }, [])
        .flat();
    const getCurrentVideo = (videoId) => {
        const id = parseInt(videoId);
        const v = transformed.filter((i) => i.videoId === id);
        if (v.length > 0) return v[0];
        else return null;
    };
    const getAllVideosTransformed = () => {
        return transformed;
    };
    const getRelatedVideos = (videoId) => {
        if (original.length === 0) return [];
        const id = parseInt(videoId);
        const categoryId = transformed.filter((i) => i.videoId === id)[0].categoryId;
        return transformed.filter((i) => i.categoryId === categoryId).filter((i) => i.videoId !== id);
    };
    const getAllCategoryVideos = (videoId) => {
        if (original.length === 0) return [];
        const id = parseInt(videoId);
        const categoryId = transformed.filter((i) => i.videoId === id)[0].categoryId;
        return transformed.filter((i) => i.categoryId === categoryId);
    };
    const getCategoryVideos = (categoryId) => {
        if (original.length === 0) return [];
        const id = parseInt(categoryId);
        return transformed.filter((i) => i.categoryId === id);
    };
    const getNextVideo = (videoId) => {
        const id = parseInt(videoId);
        const v = getAllCategoryVideos(id);
        const idx = v.findIndex((i) => i.videoId === id);
        if (idx + 1 < v.length) return v[idx + 1];
        else return null;
    };
    const getHistoryVideos = () => {
        const ids = history.map((i) => i.videoId);
        const t1 = transformed.filter((i) => ids.indexOf(i.videoId) >= 0);
        return mergeByCommonKeys(t1, history, 'videoId').map((i) => ({
            ...i,
            timestamp: formatDistance(new Date(i.timestamp), new Date(), { addSuffix: true }),
        }));
    };
    const getFavoritesVideos = () => {
        return transformed.filter((i) => favorites.indexOf(i.videoId) >= 0);
    };
    const getCategoryDetails = () => {
        return original.map(({ categoryId, categoryName }) => ({ categoryId, categoryName }));
    };
    return {
        getAllVideosTransformed,
        getCurrentVideo,
        getRelatedVideos,
        getNextVideo,
        getHistoryVideos,
        getCategoryVideos,
        getFavoritesVideos,
        getCategoryDetails,
        videos: original,
        transformed,
        favorites: favorites,
        history: history,
    };
}
