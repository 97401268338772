import { useDispatch } from 'react-redux';
import useGetPageConfig from './useGetPageConfig';
import * as actions from '../redux/actions';
import storage from '../utils/storage';

export default function useSetLanguage() {
    const { lang: activeLang } = useGetPageConfig();
    const dispatch = useDispatch();

    const handleLanguageChange = (lang) => {
        const h = document.querySelector('html');
        const _sessionStorage = storage('session');
        const rtl = ['ar'].indexOf(lang) >= 0;
        h.setAttribute('lang', lang);
        h.setAttribute('dir', rtl ? 'rtl' : 'ltr');
        _sessionStorage.add('lang', lang);
        dispatch(actions.setLang(lang));
        return;
    };

    return { activeLang, handleLanguageChange };
}
