import { parsePhoneNumber } from 'react-phone-number-input';

export default function formatMsisdn(mobilenum) {
    if (!mobilenum) return undefined;
    else {
        mobilenum = mobilenum.toString(10);
        mobilenum = mobilenum.charAt(0) !== '+' ? '+' + mobilenum : mobilenum;
        const msisdn = parsePhoneNumber(mobilenum);
        return msisdn.number;
    }
}
