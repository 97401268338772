import { useEffect, useState } from 'react';

export default function useResize() {
    const [size, setSize] = useState({
        width: window.innerWidth,
        height: window.innerHeight,
    });
    const setWindowSize = () => setSize(() => ({ width: window.innerWidth, height: window.innerHeight }));

    useEffect(() => {
        window.addEventListener('resize', setWindowSize);
        return () => window.removeEventListener('resize', setWindowSize);
    }, []);

    return size;
}
