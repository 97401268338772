import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import BottomNavbar from './components/BottomNavbar/BottonNavbar';
import Footer from './components/Footer/Footer';
import PageLoader from './components/Loaders/PageLoader';
import { _isAMBFooterSubdomain } from './constants/conditionals';
import useAuth from './hooks/useAuth';
import useInit from './hooks/useInit';
import useResize from './hooks/useResize';

export default function PrivateOutlet() {
    const { status } = useAuth();
    return status === 'success' ? <InitOutlet /> : status === 'failure' ? <Navigate to="/login" replace /> : <PageLoader />;
}

function InitOutlet() {
    const { width } = useResize();
    const { status } = useInit();
    return (
        <>
            {status === 'success' ? (
                <>
                    <Outlet />
                    {width > 576 || _isAMBFooterSubdomain ? <Footer /> : <BottomNavbar />}
                </>
            ) : status === 'failure' ? (
                <Navigate to="/login" replace />
            ) : (
                <PageLoader />
            )}
        </>
    );
}
