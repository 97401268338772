import React from 'react';
import { useLocation } from 'react-router-dom';
import { AiFillHome as HomeIcon } from 'react-icons/ai';
import {
    FaHistory as HistoryIcon,
    FaRegUserCircle as AccountIcon,
    FaSearch as SearchIcon,
    FaStar as StarIcon,
    // FaEnvelope as ContactUsIcon,
} from 'react-icons/fa';
import useResize from '../../hooks/useResize';
import useTranslate from '../../hooks/useTranslate';
import ProtectedLink from '../../utils/ProtectedLink';

export default function BottomNavbar() {
    const t = useTranslate();
    const { pathname } = useLocation();
    const { width } = useResize();

    if (width > 576) return null;
    return (
        <div className="bottom-nav">
            <BottomNavLink to="/home" Icon={HomeIcon} name={t('home')} active={pathname === '/' || pathname === '/home'} />
            <BottomNavLink to="/account" Icon={AccountIcon} name={t('my_account')} active={pathname === '/account'} />
            <BottomNavLink to="/search" Icon={SearchIcon} name={t('search')} active={pathname === '/search'} />
            <BottomNavLink to="/favorites" Icon={StarIcon} name={t('favorites')} active={pathname === '/favorites'} />
            <BottomNavLink to="/history" Icon={HistoryIcon} name={t('history')} active={pathname === '/history'} />
        </div>
    );
}

const BottomNavLink = ({ to, name, Icon, active }) => {
    return (
        <ProtectedLink to={to} className={active ? 'link-active' : ''}>
            <span>
                <Icon />
            </span>
            <span>{name}</span>
        </ProtectedLink>
    );
};
