import { useSelector } from 'react-redux';
import { config } from '../constants/domainConfig';

export default function useGetPageConfig() {
    const country = useSelector((state) => state.page.country);
    const lang = useSelector((state) => state.page.lang);
    const subdomain = config.subdomain;
    const showLanguageModal = useSelector((state) => state.page.showLanguageModal);
    const rtl = ['ar'].indexOf(lang) >= 0;
    const product = config.product;
    return { country, lang, showLanguageModal, rtl, subdomain, product };
}
