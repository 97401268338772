import React from 'react';
import classNames from 'classnames';
import { useSelector, useDispatch } from 'react-redux';
import ProtectedLink from '../../utils/ProtectedLink';
import useTranslate from '../../hooks/useTranslate';
import Avatar from '../../assets/images/avatar.png';
import { AiFillHome as HomeIcon, AiFillCloseCircle as CloseIcon } from 'react-icons/ai';
import {
    FaHistory as HistoryIcon,
    FaRegUserCircle as AccountIcon,
    FaStar as StarIcon,
    FaChevronRight as ArrowRightIcon,
    FaSignOutAlt as SignOutIcon,
    FaRegPlayCircle as VideoIcon,
    FaVideo as MovieIcon,
} from 'react-icons/fa';
import { showLangModal } from '../../redux/actions';
import useGetConfig from '../../hooks/useGetPageConfig';
import multiLangDomains from '../../constants/multiLang';
import { useNavigate } from 'react-router-dom';

export default function Sidebar({ open, onClose, rtl }) {
    const navigate = useNavigate();
    const t = useTranslate();
    const userDetails = useSelector((state) => state.user);
    const { subdomain } = useGetConfig();
    const dispatch = useDispatch();
    const openModal = () => {
        dispatch(showLangModal(true));
        onClose();
    };
    const logout = () => {
        sessionStorage.clear();
        localStorage.clear();
        navigate('/login');
    };
    return (
        <div className={classNames('sidebar', { 'sidebar-open': open })} dir={rtl ? 'rtl' : 'ltr'}>
            <div className="close">
                <button onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>
            <div className="user-profile">
                <ProtectedLink to="/account">
                    <img src={userDetails.details && userDetails.details.image ? userDetails.details.image : Avatar} alt="" />
                    {userDetails && userDetails.details && userDetails.details.name && <div className="user-name">{userDetails.details.name}</div>}
                </ProtectedLink>
            </div>
            <div className="links">
                <ProtectedLink to="/home">
                    <span>
                        <HomeIcon />
                    </span>
                    <span>{t('home')}</span>
                </ProtectedLink>
                <ProtectedLink to="/account">
                    <span>
                        <AccountIcon />
                    </span>
                    <span>{t('my_account')}</span>
                </ProtectedLink>
                <ProtectedLink to="/favorites">
                    <span>
                        <StarIcon />
                    </span>
                    <span>{t('favorites')}</span>
                </ProtectedLink>
                <ProtectedLink to="/history">
                    <span>
                        <HistoryIcon />
                    </span>
                    <span>{t('history')}</span>
                </ProtectedLink>
                <ProtectedLink to="/home/video">
                    <span>
                        <VideoIcon />
                    </span>
                    <span>{t('video')}</span>
                </ProtectedLink>
                <ProtectedLink to="/home/movie">
                    <span>
                        <MovieIcon />
                    </span>
                    <span>{t('movie')}</span>
                </ProtectedLink>
            </div>
            <div className="separator" />
            <div className="more-links">
                <button onClick={logout}>
                    <span>{t('logout')}</span>
                    <SignOutIcon />
                </button>
                {Object.keys(multiLangDomains).indexOf(subdomain) >= 0 && (
                    <button onClick={openModal}>
                        <span>{t('languages')}</span>
                        <ArrowRightIcon />
                    </button>
                )}
            </div>
        </div>
    );
}
