import classNames from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';

const FAQLink = ({ lang = 'en', text, className }) => {
    const t = useTranslate();
    const title = text ? text : t('faq');
    switch (lang) {
        default:
            return (
                <Link to="/faq" target="_blank" className={classNames({ [className]: className !== undefined })}>
                    {title}
                </Link>
            );
    }
};

export default FAQLink;
