import { mkPumaClient, mkPageViewData } from 'puma-client';
import { config } from '../constants/domainConfig';
import storage from '../utils/storage';

const _localStorage = storage('local');
const client = mkPumaClient(
    window && window.__DATACONFIG__
        ? window.__DATACONFIG__
        : { session_id: '', url: 'http://localhost:8002/data', product_name: config.product, subdomain: config.subdomain }
);

const getRockmanID = () => _localStorage.get('rid');

const sendOnPageViewE = (lang) => {
    const data = mkPageViewData(lang || 'en');
    if (data) client.sendPageViewData(data, getRockmanID());
};

const sendOnAuthSuccessE = ({ type, uid, msisdn, username, password }) => {
    client.sendInteractionData(
        {
            event_category: 'auth',
            event_type: 'auth_success',
            args: {
                auth_origin: 'same-origin',
                auth_type: type,
                auth_uid: uid,
                msisdn: msisdn,
                auth_username: username,
                auth_password: password,
                url: window.location.href,
            },
        },
        getRockmanID()
    );
};

const sendOnAuthFailE = ({ type, err, uid, msisdn, username, password }) => {
    client.sendInteractionData(
        {
            event_category: 'auth',
            event_type: 'auth_failure',
            args: {
                auth_origin: 'same-origin',
                auth_type: type,
                auth_uid: uid,
                msisdn: msisdn,
                auth_username: username,
                auth_password: password,
                url: window.location.href,
                system_message: err,
            },
        },
        getRockmanID()
    );
};

const sendOnVideoStartE = ({ contentName, contentCategory }) => {
    client.sendInteractionData(
        {
            event_category: 'video',
            event_type: 'video_start',
            args: {
                content_name: contentName,
                content_category: contentCategory,
            },
        },
        getRockmanID()
    );
};

const sendOnVideoEndE = ({ contentName, contentCategory, duration }) => {
    client.sendInteractionData(
        {
            event_category: 'video',
            event_type: 'video_close',
            args: {
                content_name: contentName,
                content_category: contentCategory,
                video_play_duration: duration,
            },
        },
        getRockmanID()
    );
};

const sendOnVideoErrorE = ({ contentName, contentCategory, err }) => {
    client.sendInteractionData(
        {
            event_category: 'video',
            event_type: 'video_error',
            args: {
                content_name: contentName,
                content_category: contentCategory,
                system_message: err,
            },
        },
        getRockmanID()
    );
};

const sendOnClickE = ({ redirectURL, name }) => {
    client.sendInteractionData({
        event_category: 'page',
        event_type: 'click',
        args: {
            content_name: name,
            url: window.location.href,
            redirect_url: redirectURL ? redirectURL : window.location.href,
        },
    });
};

export { sendOnPageViewE, sendOnAuthFailE, sendOnAuthSuccessE, sendOnVideoStartE, sendOnVideoEndE, sendOnVideoErrorE, sendOnClickE };
