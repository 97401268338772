import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import { useAlert } from '../../components/AlertContainer/AlertContainer';
import { FaSave as SaveIcon } from 'react-icons/fa';
import Avatar from '../../assets/images/avatar.png';
import Header from '../../components/Header/Header';
import * as actions from '../../redux/actions';
import { emailRegex } from '../../constants/emailRegex';
import PrivacyPolicyLink from '../../components/PrivacyPolicyLink/PrivacyPolicyLink';
import TnCLink from '../../components/TnCLink/TnCLink';
import FAQLink from '../../components/FAQLink/FAQLink';
import RefundPolicyLink from '../../components/RefundPolicyLink/RefundPolicyLink';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';
import { Link } from 'react-router-dom';
import useGetPageConfig from '../../hooks/useGetPageConfig';

export default function Account() {
    const accessDetails = useSelector((state) => state.access);
    const userDetails = useSelector((state) => state.user);
    const [userImage, setUserImage] = React.useState(userDetails.details && userDetails.details.image ? userDetails.details.image : Avatar);
    const [userState, setUserState] = React.useState(
        userDetails.details
            ? { name: userDetails.details.name, email: userDetails.details.email, image: userDetails.details.image }
            : { name: '', email: '', image: '' }
    );
    const imageRef = React.useRef(null);
    const dispatch = useDispatch();
    const { showAlert } = useAlert();
    const t = useTranslate();
    const { subdomain } = useGetPageConfig();

    const handleSave = () => {
        const body = userState;
        if (userState.email && !emailRegex.test(userState.email.toLowerCase())) {
            showAlert({ status: 'failure', msg: 'invalid_email_address' });
        } else {
            dispatch(actions.updateUserDetails(body));
            showAlert({ status: 'success', msg: 'success_updated_profile' });
        }
    };

    const handleImageChange = (e) => {
        const formdata = new FormData();
        const reader = new FileReader();
        const file = e.target.files[0];
        if (!file || file.size >= 5000000) {
            showAlert({ status: 'failure', msg: 'image_size_large' });
            return;
        } else {
            formdata.append('profile', file);
            reader.readAsDataURL(file);
            reader.onloadend = async () => {
                const blob = reader.result;
                if (imageRef.current) imageRef.current.style.filter = 'blur(2px)';
                setUserImage(() => blob);
                try {
                    const url = new URL(window.location.origin + '/api/user/upload-profile-picture').href;
                    const data = await fetch(url, {
                        body: formdata,
                        method: 'POST',
                        headers: {
                            accept: 'multipart/form-data; boundary=xxx',
                        },
                    });
                    const resp = await data.json();
                    if (!resp.success) {
                        showAlert({ status: 'failure', msg: 'unexpected_error' });
                        return;
                    } else {
                        showAlert({ status: 'success', msg: 'profile_picture_saved' });
                        const location = resp.payload.location;
                        setUserImage(() => location);
                        setUserState((prev) => ({ ...prev, image: location }));
                        if (imageRef.current) imageRef.current.style.filter = null;
                        return;
                    }
                } catch (err) {
                    showAlert({ status: 'failure', msg: 'unexpected_error' });
                }
            };
        }
    };
    const isMsisdnEmail = accessDetails && accessDetails.msisdn ? emailRegex.test(accessDetails.msisdn) : false;

    return (
        <>
            <Header headerText={t('my_account')} showActionButton ActionEl={<SaveIcon />} onAction={handleSave} />
            <div className={classNames('accounts-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })}>
                <div className="accounts-details">
                    <div className="account-details-personal">
                        <div className="account-details-personal-title">{t('account_details')}</div>
                        <AccountInputField label={t('name')} placeholder={t('your_name')} type="text" disabled={true} value={accessDetails.name || ''} />
                        <AccountInputField
                            label={isMsisdnEmail ? t('email_address') : t('mobile_number')}
                            placeholder={isMsisdnEmail ? t('your_email') : t('your_mobile_number')}
                            type="text"
                            disabled={true}
                            value={accessDetails.msisdn || ''}
                        />
                    </div>
                    <div className="account-details-links">
                        <FAQLink />
                        <RefundPolicyLink />
                        <PrivacyPolicyLink />
                        <TnCLink />
                        {/* {subdomain === 'max' && <Link to="/forgot-password">{t('forgot_password')}</Link>} */}
                    </div>
                </div>
                <label htmlFor="profile-pic" className="accounts-profile-image">
                    <input type="file" id="profile-pic" hidden accept="image/jpg, image/png, image/jpeg, image/webp" onChange={handleImageChange} />
                    <img src={userImage} alt="" ref={imageRef} />
                </label>
            </div>
        </>
    );
}

const AccountInputField = ({ type, label, value, onChange, placeholder, disabled }) => {
    return (
        <div className="account-input">
            <label>{label}</label>
            <input disabled={disabled} type={type} value={value} onChange={onChange} placeholder={placeholder} />
        </div>
    );
};
