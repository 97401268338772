import EntertainmentMobile from '../assets/banners/entertainment_mobile.png';
import HealthMobile from '../assets/banners/health_mobile.png';
import NewsMobile from '../assets/banners/news_mobile.png';
import SportsMobile from '../assets/banners/sports_mobile.png';
import Entertainment from '../assets/banners/entertainment.png';
import Health from '../assets/banners/health.png';
import News from '../assets/banners/news.png';
import Sports from '../assets/banners/sports.png';

export default function getBanners() {
    return [
        { bannerLg: Entertainment, bannerSm: EntertainmentMobile, name: 'Entertainment' },
        { bannerLg: Health, bannerSm: HealthMobile, name: 'Health & Lifestyle' },
        { bannerLg: News, bannerSm: NewsMobile, name: 'News' },
        { bannerLg: Sports, bannerSm: SportsMobile, name: 'Sports' },
    ];
}
