import en from './en.json';
import de from './de.json';
import fr from './fr.json';
import it from './it.json';
import sr from './sr.json';
import ar from './ar.json';
import es from './es.json';
import categories from './categories.json';

export const addCategoriesTranslations = (lang) => {
    const keys = Object.keys(categories);
    if (keys.includes(lang)) return categories[lang];
};

export default function getMessages(locale) {
    switch (locale) {
        case 'ar':
            return ar;
        case 'de':
            return de;
        case 'en':
            return { ...en, ...addCategoriesTranslations('en') };
        case 'es':
            return { ...es, ...addCategoriesTranslations('es') };
        case 'fr':
            return { ...fr, ...addCategoriesTranslations('fr') };
        case 'it':
            return it;
        case 'sr':
            return sr;
        default:
            return en;
    }
}
