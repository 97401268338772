import React, { useState } from 'react';
import Fuse from 'fuse.js';

export default function useGetSearchVideos(searchTerm, videos, type) {
    const [searchVideos, setSearchVideos] = useState([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const fuse = new Fuse(videos, {
        keys: ['videoTitle'],
    });
    React.useEffect(() => {
        setTimeout(() => {
            if (searchTerm && searchTerm.length > 0) {
                let t = [];
                if (type === 'exact') t = videos.filter((i) => i.videoTitle.toLowerCase().includes(searchTerm.toLowerCase()));
                else t = fuse.search(searchTerm).map((i) => i.item);
                setSearchVideos(() => t);
            } else setSearchVideos(() => []);
        }, 500);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchTerm]);

    return searchVideos;
}
