import { actionTypes } from '../actions';
import { config } from '../../constants/domainConfig';

const initState = {
    country: config.country,
    lang: config.lang,
    showLanguageModal: false,
};

const PageReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_COUNTRY:
            return { ...state, country: action.payload.toUpperCase() };
        case actionTypes.SET_LANGUAGE:
            return { ...state, lang: action.payload };
        case actionTypes.SHOW_LANG_MODAL:
            return { ...state, showLanguageModal: action.payload };
        default:
            return state;
    }
};

export default PageReducer;
