import storage from '../utils/storage';

const localCountry = 'DE'; // localhost - frontend server
const localProductName = 'entertainu';
const localSubdomain = 'demo';

const getBrowserLang = (options, defaultLang) => {
    const lang = options.filter((i) => navigator.language.includes(i))[0];
    return lang || defaultLang;
};

export const getDefaultLang = (country) => {
    switch (country) {
        default:
            return getBrowserLang(['en', 'fr', 'es'], 'en');
    }
};

export const getProductName = () => {
    if (window.location.hostname.includes('localhost')) return localProductName;
    const options = ['movio', 'moviotv', 'fun-box', 'entertainu'];
    const baseDomain = window.location.hostname.split('.')[1];
    const productName = options.indexOf(baseDomain) >= 0 ? baseDomain : options[1]; // moviotv as default product
    console.log(productName);
    return productName;
};

export const getCountry = () => {
    const pageConfig = window.__PAGECONFIG__;
    if (pageConfig && pageConfig.ip_country_code && pageConfig.ip_country_code !== 'XX') return pageConfig.ip_country_code.toUpperCase();
    else return localCountry.toUpperCase();
};

export const getLanguage = (country) => {
    const _sessionStorage = storage('session');
    const queryLang = new URL(window.location.href).searchParams.get('language');
    if (queryLang) _sessionStorage.add('lang', queryLang.toLowerCase());
    const lang = _sessionStorage.get('lang') || getDefaultLang(country);
    return lang;
};

export const getSubdomain = () => {
    if (window.location.hostname.includes('localhost')) return localSubdomain;
    const parts = window.location.hostname.split('.');
    if (parts.length < 3) return 'demo'; // use demo when no subdomain - eg - movio.io
    else return parts[0];
};

export const getConfigs = () => {
    const product = getProductName();
    const country = getCountry();
    const lang = getLanguage(country);
    const subdomain = getSubdomain();
    return { country, lang, product, subdomain };
};

export const config = getConfigs(getSubdomain());
