import React from 'react';
import { useCookies } from 'react-cookie';
import { AiOutlineCloseSquare as CloseIcon } from 'react-icons/ai';
import useTranslate from '../../hooks/useTranslate';
import { config } from '../../constants/domainConfig';
import PrivacyPolicyLink from '../PrivacyPolicyLink/PrivacyPolicyLink';

export default function CookieBanner() {
    const t = useTranslate();
    const [cookies, setCookie] = useCookies([config.product]);

    if (cookies[config.product]) return null;

    return (
        <div className="cookie-banner-top">
            <div className="cookie-banner-title">
                <span className="cookie-text">{t('cookie_consent_text')}</span>
                <button
                    className="cookie-button"
                    onClick={() => {
                        setCookie(config.product, true, { path: '/', maxAge: 2592000 });
                    }}
                >
                    <CloseIcon />
                </button>
            </div>
            <div className="cookie-banner-accept">
                {t('cookie_consent_link', {
                    a: (text) => <PrivacyPolicyLink text={text} />,
                })}
            </div>
        </div>
    );
}
