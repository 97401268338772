import { useState } from 'react';

export default function useSearch(open = false) {
    const [searchOpen, setSearchOpen] = useState(open);
    const [searchTerm, setSearchTerm] = useState('');

    const onSearchOpen = () => setSearchOpen(() => true);
    const onSearchClose = () => {
        setSearchTerm(() => '');
        setSearchOpen(() => false);
    };
    const onSearchChange = (ev) => setSearchTerm(() => ev.target.value);
    const onSearchClear = () => setSearchTerm(() => '');

    return { searchOpen, searchTerm, onSearchOpen, onSearchClose, onSearchChange, onSearchClear };
}
