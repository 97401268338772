const ccList = [
    {
        label: 'English',
        value: 'en',
    },
    {
        label: 'Français',
        value: 'fr',
    },
    {
        label: 'Español',
        value: 'es',
    },
];
const multiLangDomains = {
    demo: [
        {
            label: 'English',
            value: 'en',
        },
    ],
    portal: ccList,
    max: ccList,
};

export default multiLangDomains;
