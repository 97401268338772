import React from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import { useNavigate, useParams } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';
import Header from '../../components/Header/Header';
import useGetMovies from '../../hooks/useMovies';
import makeApiRequest from '../../utils/makeApiRequest';
import storage from '../../utils/storage';
import useResize from '../../hooks/useResize';
import Slider from '../../components/Slider/Slider';
import VideoCard from '../../components/VideoCard/VideoCard';
import VideoList from '../../components/VideoList/VideoList';
import VideoJSPlayerStreaming from '../../components/VideoJSPlayer/VideoJSPlayerStreaming';
import PageLoader from '../../components/Loaders/PageLoader';
import { addToHistory } from '../../redux/actions';
import { sendOnVideoEndE, sendOnVideoErrorE, sendOnVideoStartE } from '../../analytics/data-collection';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';

export default function MoviePlay() {
    const [loading, setLoading] = React.useState(true);
    const [movieURL, setMovieURL] = React.useState('');
    const { id: videoId } = useParams();
    const { getCurrentMovie, getRelatedMovies } = useGetMovies();
    const currVideo = getCurrentMovie(videoId);
    const relatedMovies = getRelatedMovies(videoId);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { rtl } = useGetPageConfig();
    const uid = useSelector((state) => state.user.token);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const goBack = React.useCallback(() => navigate(-1), []);

    React.useEffect(() => {
        const getMovieURL = async (id, uid) => {
            setLoading(true);
            if (!uid) {
                goBack();
                return;
            }
            const _sessionStorage = storage('session');
            try {
                const resp = await makeApiRequest({
                    url: new URL(window.location.origin + '/api/videos/movies/url').href,
                    method: 'POST',
                    body: { videoId: id, uid },
                    headers: { authorization: _sessionStorage.get('authorization') },
                });
                if (resp.success) {
                    setLoading(false);
                    return resp.payload;
                } else {
                    setLoading(false);
                    goBack();
                    return;
                }
            } catch (err) {
                setLoading(false);
                goBack();
                return;
            }
        };
        getMovieURL(currVideo.videoId, uid)
            .then((resp) => {
                setMovieURL(resp);
            })
            .catch(() => goBack());
    }, [currVideo.videoId, uid, goBack]);
    if (loading) return <PageLoader />;
    return (
        <>
            <Header showBackButton onBackButton={() => navigate('/home/movie')} forceHideHamburger />
            <div
                className={classNames('movie-view-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })}
                key={currVideo.videoId}
                dir={rtl ? 'rtl' : 'ltr'}
            >
                <div className="movie-view-preview">
                    <VideoJSPlayerStreaming
                        className="videojs-custom"
                        id={currVideo.videoId.toString()}
                        src={movieURL}
                        poster={currVideo.videoPreview}
                        onEnd={() => {
                            console.log('Video Ended');
                        }}
                        onPlay={() => {
                            dispatch(addToHistory(currVideo.videoId, 'movies'));
                            sendOnVideoStartE({ contentName: currVideo.videoTitle, contentCategory: currVideo.categoryName });
                        }}
                        onDispose={(time) => {
                            sendOnVideoEndE({ contentName: currVideo.videoTitle, contentCategory: currVideo.categoryName, duration: time });
                        }}
                        onError={(err) => {
                            sendOnVideoErrorE({ contentName: currVideo.videoTitle, contentCategory: currVideo.categoryName, err: err });
                        }}
                    />
                    <div className="movie-view-desc">
                        <div className="movie-view-title">{currVideo.videoTitle}</div>
                        {currVideo.videoMetadata ? (
                            <div className="movie-view-other-details">
                                {currVideo.videoMetadata.releaseYear ? <p>{`Released: ${currVideo.videoMetadata.releaseYear}`}</p> : <></>}
                                {currVideo.videoMetadata.actors ? <p>{`Actors: ${currVideo.videoMetadata.actors}`}</p> : <></>}
                            </div>
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
                {relatedMovies && relatedMovies.length > 0 ? (
                    <RelatedMoviesList
                        relatedMovies={relatedMovies}
                        onClick={(videoId) => {
                            navigate('/play/movie/' + videoId);
                        }}
                    />
                ) : (
                    <></>
                )}
            </div>
        </>
    );
}

const RelatedMoviesList = ({ relatedMovies, onClick }) => {
    const { width } = useResize();
    const t = useTranslate();
    return (
        <>
            {width > 576 ? (
                <div className="related-movies">
                    <div className="related-movies-header">
                        <div title="related-movies-title">{t('related_videos')}</div>
                    </div>
                    <div className="related-movies-contents">
                        <Slider
                            slides={relatedMovies.map((item) => {
                                return (
                                    <VideoCard
                                        key={item.videoId}
                                        type={item.itemType}
                                        id={item.videoId}
                                        title={item.videoTitle}
                                        imageURL={item.videoPreview}
                                        onClick={() => onClick(item.videoId)}
                                    />
                                );
                            })}
                        />
                    </div>
                </div>
            ) : (
                <div className="related-movies">
                    <div className="related-movies-header">
                        <div title="related-movies-title">{t('related_videos')}</div>
                    </div>
                    <div className="related-movies-contents">
                        {relatedMovies.map((item) => (
                            <VideoList
                                key={item.videoId}
                                type={item.itemType}
                                id={item.videoId}
                                imageURL={item.videoPreview}
                                title={item.videoTitle}
                                onClick={() => onClick(item.videoId)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
