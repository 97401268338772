import React from 'react';
import Header from '../../components/Header/Header';
import createDOMPurify from 'dompurify';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import PrivacyPolicyMovioEN from './translations/en/PrivacyPolicy.html';

const sanitize = (html) => {
    if (!window) return '';
    return createDOMPurify(window).sanitize(html);
};

const getPrivacyPolicyTranslated = (lang) => {
    switch (lang) {
        default:
            return PrivacyPolicyMovioEN;
    }
};

const PrivacyPolicy = () => {
    const { lang } = useGetPageConfig();
    return (
        <>
            <Header forceHideHamburger />
            <div className="static-pages">
                <div dangerouslySetInnerHTML={{ __html: sanitize(getPrivacyPolicyTranslated(lang)) }} />
            </div>
        </>
    );
};

export default PrivacyPolicy;
