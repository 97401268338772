import React from 'react';

export default function Toggler({ title, checked, setChecked }) {
    return (
        <div className="toggler-wrapper">
            {title && <div className="toggler-title">{title}</div>}
            <label className="toggler">
                <input type="checkbox" checked={checked} onChange={setChecked} />
                <span></span>
            </label>
        </div>
    );
}
