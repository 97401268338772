import React from 'react';
import Header from '../../components/Header/Header';
import createDOMPurify from 'dompurify';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import FAQMovioEN from './translations/en/FAQ.html';
import FaqFunBox from './translations/en/FaqFunbox.html';
import FaqEntertainu from './translations/en/FaqEntertainu.html';

const sanitize = (html) => {
    if (!window) return '';
    return createDOMPurify(window).sanitize(html);
};

const getFAQTranslated = (product) => {
    switch (product) {
        case 'fun-box':
            return FaqFunBox;
        case 'entertainu':
            return FaqEntertainu;
        default:
            return FAQMovioEN;
    }
};

const FAQ = () => {
    const { lang, product, subdomain } = useGetPageConfig();
    let faqTemplate = getFAQTranslated(product);
    return (
        <>
            <Header forceHideHamburger />
            <div className="static-pages">
                <div dangerouslySetInnerHTML={{ __html: sanitize(faqTemplate) }} />
            </div>
        </>
    );
};

export default FAQ;
