import { actionTypes } from '../actions';

const AccessReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.SET_ACCESS_DETAILS:
            return { ...state, ...action.payload };
        default:
            return state;
    }
};

export default AccessReducer;
