import axios from 'axios';

export default async function makeApiRequest({ url, method, body, headers }) {
    const response = await axios({
        url,
        method,
        headers: {
            Accept: 'application/json',
            ...headers,
        },
        data: body,
    });
    const data = await response.data;
    return data;
    // const fetchOptions = {
    //     method: method,
    //     body: body ? JSON.stringify(body) : undefined,
    //     headers: {
    //         'Content-Type': 'application/json',
    //         Accept: 'application/json',
    //         ...headers,
    //     },
    // };
    // const response = await fetch(url, fetchOptions);
    // const data = await response.json();
    // return data;
}
