import React from 'react';
// import LoginWithMsisdn from './LoginWithMsisdn';
import LoginWithEmailAndAccessLink from './LoginWithEmailAndAccessLink';
import LoginWithEmailAndPassword from './LoginWithEmailAndPassword';
import { isCreditCardPortal } from '../../constants/cc';

const Login = () => {
    return isCreditCardPortal() ? <LoginWithEmailAndAccessLink /> : <LoginWithEmailAndPassword />;
};

export default Login;
