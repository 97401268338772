import React from 'react';
import { FaSpinner } from 'react-icons/fa';
import logo from '../../assets/logos/logo-movio.svg';
import LogoEntertainu from '../../assets/logos/entertainu_w.svg';
import LogoFunbox from '../../assets/logos/funbox.svg';
import { config } from '../../constants/domainConfig';

export default function PageLoader() {
    return (
        <div className="page-loader">
            <img src={config.product === 'fun-box'? LogoFunbox : config.product === 'entertainu'? LogoEntertainu : logo} alt="" />
            <FaSpinner />
        </div>
    );
}
