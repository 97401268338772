import { useSelector } from 'react-redux';
import mergeByCommonKeys from '../utils/mergeByCommonKey';
import { formatDistance } from 'date-fns';

export default function useGetMovies() {
    const movies = useSelector((state) => state.movie.movies);
    const favorites = useSelector((state) => state.movie.moviesFavorites);
    const history = useSelector((state) => state.movie.moviesHistory);

    const getCurrentMovie = (videoId) => {
        const id = parseInt(videoId);
        const v = movies.filter((i) => i.videoId === id);
        if (v.length > 0) return v[0];
        else return null;
    };
    const getRelatedMovies = (videoId) => {
        const id = parseInt(videoId);
        return movies.filter((x) => x.videoId !== id);
    };
    const getCategoryDetails = () => {
        const categoryIds = Array.from(new Set(movies.map((x) => x.categoryId)));
        return categoryIds.map((x) => ({
            categoryId: x,
            categoryName: movies.find((y) => y.categoryId === x).categoryName,
        }));
    };
    const getCategoryVideos = (categoryId) => {
        const id = parseInt(categoryId);
        return movies.filter((x) => x.categoryId === id);
    };
    const getFavoritesMovies = () => {
        return movies.filter((i) => favorites.indexOf(i.videoId) >= 0);
    };
    const getHistoryMovies = () => {
        const ids = history.map((i) => i.videoId);
        const t1 = movies.filter((i) => ids.indexOf(i.videoId) >= 0);
        return mergeByCommonKeys(t1, history, 'videoId').map((i) => ({
            ...i,
            timestamp: formatDistance(new Date(i.timestamp).getTime(), new Date().getTime(), { addSuffix: true }),
        }));
    };
    return { movies, favorites, history, getCurrentMovie, getRelatedMovies, getCategoryDetails, getCategoryVideos, getFavoritesMovies, getHistoryMovies };
}
