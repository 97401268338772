import React from 'react';
import Header from '../../components/Header/Header';
import createDOMPurify from 'dompurify';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import RefundPolicyMovioEN from './translations/en/RefundPolicy.html';
import { isCreditCardPortal } from '../../constants/cc';

const sanitize = (html) => {
    if (!window) return '';
    return createDOMPurify(window).sanitize(html);
};

const getRefundPolicyTranslated = (lang) => {
    switch (lang) {
        default:
            return RefundPolicyMovioEN;
    }
};

const RefundPolicy = () => {
    const { lang } = useGetPageConfig();
    let faqTemplate = getRefundPolicyTranslated(lang);
    if (isCreditCardPortal()) {
        faqTemplate = faqTemplate.replaceAll('No refunds will be issued after the first 3 days of service.', '');
    }
    return (
        <>
            <Header forceHideHamburger />
            <div className="static-pages">
                <div dangerouslySetInnerHTML={{ __html: sanitize(faqTemplate) }} />
            </div>
        </>
    );
};

export default RefundPolicy;
