import { config } from '../constants/domainConfig';
import formatMsisdn from './formatMsisdn';
import getSearchParams from './getSearchParams';
import storage from './storage';

export default function getAccessMgmtUrl({ _msisdn, _email, _password }) {
    const _localStorage = storage('local');
    const uid = getSearchParams('uid') || getSearchParams('token') || _localStorage.get('uid');
    const rid = getSearchParams('rid');
    const msisdn = getSearchParams('msisdn') || formatMsisdn(_msisdn);
    const domain = window.location.hostname; // default

    if (!uid && !rid && !msisdn && !_email && !_password)
        return {
            type: undefined,
            url: null,
            value: null,
        };

    const url = new URL('https://loginprime.com/auth');
    url.searchParams.append('domain', domain);
    if (_email && _password) {
        url.searchParams.append('username', _email);
        url.searchParams.append('password', _password);
        return { type: 'username+password', url: url.href, value: null };
    } else if (msisdn) {
        url.searchParams.append('msisdn', msisdn);
        return { type: 'msisdn', url: url.href, value: msisdn };
    } else if (rid) {
        url.searchParams.append('rid', rid);
        return { type: 'uid', url: url.href, value: rid };
    } else if (uid) {
        url.searchParams.append('uid', uid);
        return { type: 'uid', url: url.href, value: uid };
    } else {
        return { type: '', url: url.href, value: null };
    }
}
