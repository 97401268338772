import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ProtectedLink = ({ to, children, ...props }) => {
    const { token: uid } = useSelector((state) => state.access);
    return (
        <Link to={{ pathname: to, search: uid ? `?uid=${uid}` : undefined }} {...props}>
            {children}
        </Link>
    );
};

export default ProtectedLink;
