import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { FaTag as TagIcon, FaStar as StarIcon } from 'react-icons/fa';
// import { AiFillLike as LikeIcon } from 'react-icons/ai';
import useTranslate from '../../hooks/useTranslate';
import useResize from '../../hooks/useResize';
import useGetVideos from '../../hooks/useGetVideos';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import VideoJSPlayer from '../../components/VideoJSPlayer/VideoJSPlayer';
import Header from '../../components/Header/Header';
import VideoCard from '../../components/VideoCard/VideoCard';
import Slider from '../../components/Slider/Slider';
import Toggler from '../../components/Toggler/Toggler';
import VideoList from '../../components/VideoList/VideoList';
import { useAlert } from '../../components/AlertContainer/AlertContainer';
import { addToHistory, addFavourite, removeFavourite } from '../../redux/actions';
import { sendOnVideoEndE, sendOnVideoErrorE, sendOnVideoStartE } from '../../analytics/data-collection';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';

export default function VideoPlay() {
    const [autoplay, setAutoplay] = React.useState(false);
    const { id: videoId } = useParams();
    const { getCurrentVideo, getRelatedVideos, getNextVideo, favorites: favouriteIds } = useGetVideos();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const currVideo = getCurrentVideo(videoId);
    const relatedVideos = getRelatedVideos(videoId);
    const nextVideo = getNextVideo(videoId);
    const { showAlert } = useAlert();
    const { rtl } = useGetPageConfig();
    const t = useTranslate();

    const toggleFavourite = () => {
        if (!currVideo) return;
        if (favouriteIds.indexOf(currVideo.videoId) >= 0) {
            dispatch(removeFavourite(currVideo.videoId, 'videos'));
            showAlert({ status: 'failure', msg: 'success_remove_favorites' });
        } else {
            dispatch(addFavourite(currVideo.videoId, 'videos'));
            showAlert({ status: 'success', msg: 'success_add_favorites' });
        }
    };
    return (
        <>
            <Header forceHideHamburger showBackButton onBackButton={() => navigate('/home/video')} />
            {currVideo && (
                <div
                    className={classNames('video-play-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })}
                    key={currVideo.videoId}
                    dir={rtl ? 'rtl' : 'ltr'}
                >
                    <div className="video-player">
                        <VideoJSPlayer
                            className="videojs-custom"
                            id={currVideo.videoId}
                            src={currVideo.videoUrl}
                            poster={currVideo.videoPreview}
                            onEnd={() => {
                                if (nextVideo && autoplay) {
                                    navigate('/play/video/' + nextVideo.videoId);
                                }
                            }}
                            onPlay={() => {
                                dispatch(addToHistory(currVideo.videoId, 'videos'));
                                sendOnVideoStartE({ contentName: currVideo.videoTitle, contentCategory: currVideo.categoryName });
                            }}
                            onDispose={(time) => {
                                sendOnVideoEndE({ contentName: currVideo.videoTitle, contentCategory: currVideo.categoryName, duration: time });
                            }}
                            onError={(err) => {
                                sendOnVideoErrorE({ contentName: currVideo.videoTitle, contentCategory: currVideo.categoryName, err: err });
                            }}
                        />
                    </div>
                    <div className="video-desc">
                        <div className="video-desc-col">
                            <div className="title">{currVideo.videoTitle}</div>
                            <div className="tags">
                                <TagIcon />
                                {t(currVideo.categoryName)}
                            </div>
                        </div>
                        <div className="video-desc-col">
                            <div
                                className={classNames('fav', {
                                    'fav-active': favouriteIds.indexOf(currVideo.videoId) >= 0,
                                })}
                                onClick={toggleFavourite}
                            >
                                <StarIcon />
                            </div>
                            {/* <div className="like">
                                <LikeIcon />
                            </div> */}
                        </div>
                    </div>
                    {relatedVideos && <RelatedVideosList relatedVideos={relatedVideos} autoplay={autoplay} setAutoplay={() => setAutoplay((prev) => !prev)} />}
                </div>
            )}
        </>
    );
}

const RelatedVideosList = ({ relatedVideos, autoplay, setAutoplay }) => {
    const { width } = useResize();
    const navigate = useNavigate();
    const t = useTranslate();
    return (
        <>
            {width > 576 ? (
                <div className="related-videos">
                    <div className="related-video-header">
                        <div title="related-video-title">{t('related_videos')}</div>
                        <Toggler checked={autoplay} setChecked={setAutoplay} title={t('autoplay')} />
                    </div>
                    <div className="related-video-contents">
                        <Slider
                            slides={relatedVideos.map((item) => {
                                return (
                                    <VideoCard
                                        key={item.videoId}
                                        id={item.videoId}
                                        title={item.videoTitle}
                                        imageURL={item.videoPreview}
                                        onClick={() => navigate('/play/video/' + item.videoId)}
                                    />
                                );
                            })}
                        />
                    </div>
                </div>
            ) : (
                <div className="related-videos">
                    <div className="related-video-header">
                        <div title="related-video-title">{t('related_videos')}</div>
                        <Toggler checked={autoplay} setChecked={setAutoplay} title={t('autoplay')} />
                    </div>
                    <div className="related-video-contents">
                        {relatedVideos.map((item) => (
                            <VideoList
                                key={item.videoId}
                                id={item.videoId}
                                imageURL={item.videoPreview}
                                title={item.videoTitle}
                                onClick={() => navigate('/play/video/' + item.videoId)}
                            />
                        ))}
                    </div>
                </div>
            )}
        </>
    );
};
