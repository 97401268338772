import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import useTranslate from '../../hooks/useTranslate';
import useGetVideos from '../../hooks/useGetVideos';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import { useAlert } from '../AlertContainer/AlertContainer';
import { addFavourite, removeFavourite } from '../../redux/actions';
import { FaPlayCircle as PlayIcon, FaStar as StarIcon } from 'react-icons/fa';
import useGetMovies from '../../hooks/useMovies';

export default function VideoList({ id, type, imageURL, title, onClick, addlText, className }) {
    const t = useTranslate();
    const dispatch = useDispatch();
    const { showAlert } = useAlert();
    const { favorites: favouriteIdsVideos } = useGetVideos();
    const { favorites: favouriteIdsMovies } = useGetMovies();
    const favouriteIds = [...favouriteIdsMovies, ...favouriteIdsVideos];
    const active = favouriteIds.indexOf(id) >= 0;
    const { rtl } = useGetPageConfig();

    const toggleFavourite = () => {
        if (active) {
            dispatch(removeFavourite(id, type));
            showAlert({ status: 'failure', msg: 'success_remove_favorites' });
        } else {
            dispatch(addFavourite(id, type));
            showAlert({ status: 'success', msg: 'success_add_favorites' });
        }
    };

    return (
        <div
            className={classNames('video-list-item', { [className]: className !== undefined, 'video-list-item-movies': type === 'movies' })}
            id={id}
            dir={rtl ? 'rtl' : 'ltr'}
        >
            <div className="video-list-item-image" onClick={onClick}>
                <img src={imageURL} alt={title} />
            </div>
            <div className="video-list-item-details" onClick={onClick}>
                <div className="video-list-item-name">{title}</div>
                {addlText ? (
                    <div className="video-list-item-addl">{addlText}</div>
                ) : (
                    <button>
                        <span>{t('watch_now')}</span>
                        <PlayIcon />
                    </button>
                )}
            </div>
            <div
                className={classNames('video-list-item-fav', {
                    active: active,
                })}
                onClick={toggleFavourite}
            >
                <StarIcon />
            </div>
        </div>
    );
}
