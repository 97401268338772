import React from 'react';
import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';

export default function Banner({ className, src, url }) {
    const navigate = useNavigate();
    return (
        <div
            className={classNames(className, 'banner')}
            onClick={() => {
                if (url) navigate(url);
                else return;
            }}
        >
            <img src={src} alt="" />
        </div>
    );
}
