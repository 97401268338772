import React from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-simple-flex-grid';
import { FaHistory as HistoryIcon, FaTrashAlt as ClearIcon } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';
import useGetVideos from '../../hooks/useGetVideos';
import useResize from '../../hooks/useResize';
import Header from '../../components/Header/Header';
import VideoCard from '../../components/VideoCard/VideoCard';
import VideoList from '../../components/VideoList/VideoList';
import { clearHistory } from '../../redux/actions';
import classNames from 'classnames';
import useGetMovies from '../../hooks/useMovies';
import { _isAMBFooterSubdomain } from '../../constants/conditionals';

export default function History() {
    const [value, setValue] = React.useState('video');
    const t = useTranslate();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { width } = useResize();
    const { getHistoryVideos } = useGetVideos();
    const { getHistoryMovies } = useGetMovies();
    const handleClear = () => dispatch(clearHistory());
    const historyVideos = value === 'movie' ? getHistoryMovies() : getHistoryVideos();

    return (
        <>
            <Header headerText={t('history')} showActionButton ActionEl={<ClearIcon />} onAction={handleClear} />
            <div className={classNames('history-page', { 'page-layout-amb-footer': _isAMBFooterSubdomain })}>
                <RadioButtonsToggler name="type" values={['movie', 'video']} onChange={(v) => setValue(v)} currentValue={value} />
                <div className="history-videos">
                    {historyVideos && historyVideos.length > 0 ? (
                        width > 576 ? (
                            <Row
                                gutter={40}
                                style={{
                                    marginTop: '1rem',
                                }}
                            >
                                {historyVideos.map((item) => (
                                    <Col key={item.videoId} xs={6} sm={4} md={4} lg={3} xl={3}>
                                        <div className="history-video-item-lg">
                                            <VideoCard
                                                id={item.videoId}
                                                type={item.itemType}
                                                title={item.videoTitle}
                                                imageURL={item.videoPreview}
                                                onClick={() => navigate(`${item.itemType === 'movies' ? '/play/movie/' : '/play/video/'}` + item.videoId)}
                                                className="history-video-card"
                                            />
                                            <div className="history-video-item-lg-time">{item.timestamp}</div>
                                        </div>
                                    </Col>
                                ))}
                            </Row>
                        ) : (
                            <div className="history-videos-list">
                                {historyVideos.map((item) => (
                                    <VideoList
                                        key={item.videoId}
                                        type={item.itemType}
                                        id={item.videoId}
                                        imageURL={item.videoPreview}
                                        title={item.videoTitle}
                                        addlText={item.timestamp}
                                        onClick={() => navigate(`${item.itemType === 'movies' ? '/play/movie/' : '/play/video/'}` + item.videoId)}
                                    />
                                ))}
                            </div>
                        )
                    ) : (
                        <div className="no-history-videos">
                            <HistoryIcon />
                            <div>{t('no_history_videos')}</div>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
}

const RadioButtonsToggler = ({ name, values, onChange, currentValue }) => {
    const t = useTranslate();
    return (
        <div className="radio-button-toggler">
            <>
                {values.map((x) => (
                    <div key={x}>
                        <input type="radio" value={x} checked={x === currentValue} name={name} id={x} onChange={(e) => onChange(e.target.value)} />
                        <label htmlFor={x}>{t(x)}</label>
                    </div>
                ))}
            </>
        </div>
    );
};
