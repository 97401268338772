import { actionTypes } from '../actions';

const UserReducer = (state = null, action) => {
    switch (action.type) {
        case actionTypes.SET_USER_DETAILS:
            return { ...state, ...action.payload };
        case actionTypes.UPDATE_USER_DETAILS:
            return { ...state, details: action.payload };
        default:
            return state;
    }
};

export default UserReducer;
