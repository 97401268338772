import React from 'react';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';
import { FaStar } from 'react-icons/fa';
import useGetVideos from '../../hooks/useGetVideos';
import useGetPageConfig from '../../hooks/useGetPageConfig';
import { useAlert } from '../AlertContainer/AlertContainer';
import * as actions from '../../redux/actions';
import useGetMovies from '../../hooks/useMovies';

export default function VideoCard({ id, type, imageURL, title, onClick, style, className }) {
    const dispatch = useDispatch();
    const { showAlert } = useAlert();
    const { favorites: favouriteIdsVideos } = useGetVideos();
    const { favorites: favouriteIdsMovies } = useGetMovies();
    const favouriteIds = [...favouriteIdsMovies, ...favouriteIdsVideos];
    const active = favouriteIds.indexOf(id) >= 0;
    const { rtl } = useGetPageConfig();

    const toggleFavourite = () => {
        if (active) {
            dispatch(actions.removeFavourite(id, type));
            showAlert({ status: 'failure', msg: 'success_remove_favorites' });
        } else {
            dispatch(actions.addFavourite(id, type));
            showAlert({ status: 'success', msg: 'success_add_favorites' });
        }
    };
    return (
        <div
            className={classNames('video-card', {
                [className]: className !== undefined,
                'video-card-movies': type === 'movies',
            })}
            id={id.toString(10)}
            style={style}
            dir={rtl ? 'rtl' : 'ltr'}
        >
            <div className="image" onClick={onClick}>
                <img src={imageURL} alt={title} />
            </div>
            <div className="title">{title}</div>
            <button
                className={classNames('fav-button', {
                    active: active,
                })}
                onClick={toggleFavourite}
            >
                <FaStar />
            </button>
        </div>
    );
}
