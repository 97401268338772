import React from 'react';
import classNames from 'classnames';
import { AiFillCloseCircle as CloseIcon } from 'react-icons/ai';
import useTranslate from '../../hooks/useTranslate';

const Modal = React.forwardRef(({ children, className, style, onClose }, ref) => {
    const modalRef = React.useRef(null);
    React.useEffect(() => {
        const handleOutsideClick = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) onClose();
        };

        document.addEventListener('mousedown', handleOutsideClick);
        return () => document.removeEventListener('mousedown', handleOutsideClick);
    }, [onClose]);
    return (
        <div className={classNames('modal-wrapper', { [className]: className })} style={{ ...style }} ref={ref}>
            <div className="modal-container" ref={modalRef}>
                {children}
            </div>
        </div>
    );
});

Modal.displayName = 'Modal';
export { Modal };

export const LanguageModal = ({ activeLang, multiLangData, onLanguageChange, onClose, rtl }) => {
    const t = useTranslate();
    return (
        <Modal className="lang-modal" onClose={onClose}>
            <div className="lang-modal-header" dir={rtl ? 'rtl' : 'ltr'}>
                <div className="title">{t('languages')}</div>
                <button className="close-button" onClick={onClose}>
                    <CloseIcon />
                </button>
            </div>
            <div className="lang-modal-contents">
                {multiLangData.map((item, idx) => {
                    return (
                        <button
                            key={idx}
                            className={classNames('lang-modal-item', {
                                'lang-modal-item-active': activeLang === item.value,
                            })}
                            onClick={() => onLanguageChange(item)}
                        >
                            {item.label}
                        </button>
                    );
                })}
            </div>
        </Modal>
    );
};
