import { actionTypes } from '../actions';

const initState = {
    movies: [],
    moviesFavorites: [],
    moviesHistory: [],
};

const MovieReducer = (state = initState, action) => {
    switch (action.type) {
        case actionTypes.SET_MOVIES:
            return { ...state, movies: [...action.payload] };
        case actionTypes.SET_MOVIE_FAVOURITE:
            return { ...state, moviesFavorites: [...state.moviesFavorites, ...action.payload.movies] };
        case actionTypes.ADD_FAVOURITE_MOVIE:
            return { ...state, moviesFavorites: [...state.moviesFavorites, action.payload] };
        case actionTypes.REMOVE_FAVOURITE_MOVIE:
            return { ...state, moviesFavorites: state.moviesFavorites.filter((i) => i !== action.payload) };
        case actionTypes.SET_MOVIE_HISTORY:
            return { ...state, moviesHistory: action.payload.movies };
        case actionTypes.ADD_TO_MOVIE_HISTORY: {
            const t = state.moviesHistory.filter((i) => i.videoId !== action.payload.videoId);
            return { ...state, moviesHistory: [...t, action.payload] };
        }
        case actionTypes.CLEAR_MOVIE_HISTORY: {
            return { ...state, moviesHistory: [] };
        }
        default:
            return state;
    }
};

export default MovieReducer;
