import React from 'react';
import useTranslate from '../../hooks/useTranslate';

const AlertContext = React.createContext('');

const AlertContainer = ({ children }) => {
    let timeout = null;
    const t = useTranslate();
    const alertRef = React.useRef(null);
    const [state, setState] = React.useState({ msg: '', status: 'idle' });

    const showAlert = ({ status, msg }) => {
        setState(() => ({ status, msg }));
        if (timeout) {
            clearTimeout(timeout);
            timeout = null;
        }
        if (alertRef && alertRef.current) alertRef.current.classList.add(`alert-${status}`);
        timeout = setTimeout(() => {
            setState((prev) => ({ ...prev, status: 'idle' }));
            alertRef.current.classList.remove(`alert-${status}`);
        }, 2000);
    };

    return (
        <AlertContext.Provider value={{ showAlert }}>
            <>
                <div ref={alertRef} className="alert">
                    {state.msg ? t(state.msg) : ''}
                </div>
                {children}
            </>
        </AlertContext.Provider>
    );
};

const useAlert = () => {
    const context = React.useContext(AlertContext);
    return context;
};

export { AlertContainer, useAlert };
