import React from 'react';
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import useTranslate from '../../hooks/useTranslate';
import * as actions from '../../redux/actions';
import { FaSpinner } from 'react-icons/fa';
import Header from '../../components/Header/Header';
import makeApiRequest from '../../utils/makeApiRequest';
import getAccessMgmtUrl from '../../utils/getAccessMgmtUrl';
import storage from '../../utils/storage';
import Footer from '../../components/Footer/Footer';
import { emailRegex } from '../../constants/emailRegex';
import { sendOnAuthFailE, sendOnAuthSuccessE } from '../../analytics/data-collection';

const LoginWithEmailAndPassword = () => {
    const t = useTranslate();
    const _localStorage = storage('local');
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const [loading, setLoading] = React.useState(false);
    const [errorMsg, setErrorMsg] = React.useState('');

    const onLogin = async () => {
        setLoading(true);
        if (!email) {
            setLoading(() => false);
            setErrorMsg(() => 'email_id_required');
            sendOnAuthFailE({ type: 'username+password', err: 'email_id_required' });
            return;
        }
        if (!password) {
            setLoading(() => false);
            setErrorMsg(() => 'password_required');
            sendOnAuthFailE({ type: 'username+password', err: 'password_required' });
            return;
        }
        if (!emailRegex.test(email)) {
            setLoading(() => false);
            setErrorMsg(() => 'invalid_email_address');
            sendOnAuthFailE({ type: 'username+password', err: 'invalid_email_address' });
            return;
        }
        const { url: accHref } = getAccessMgmtUrl({ _email: email, _password: password });
        if (!accHref) {
            setLoading(() => false);
            dispatch(actions.setErrorDetails('access-error: ' + 'server_error'));
            setErrorMsg(() => 'server_error');
            return;
        }
        try {
            const resp = await makeApiRequest({
                method: 'GET',
                url: accHref,
            });
            if (!resp.success) {
                const err = resp.error_reason;
                setLoading(() => false);
                dispatch(actions.setErrorDetails('access-error: ' + err));
                setErrorMsg(() => err);
                sendOnAuthFailE({ type: 'username+password', err: err });
                return;
            }
            const { token: uid } = resp.payload;
            dispatch(actions.setAccessDetails(resp.response));
            _localStorage.add('uid', uid);
            sendOnAuthSuccessE({ type: 'username+password', username: email, password: password });
            navigate('/home');
        } catch (err) {
            setLoading(() => false);
            setErrorMsg(() => err.response.data.error_reason || 'unexpected_error');
            sendOnAuthFailE({ type: 'username+password', err: err.response.data.error_reason || 'unexpected_error' });
            return;
        }
    };
    return (
        <>
            <Header forceHideHamburger />
            <div className="login-page">
                <div className="login-page-title">{t('are_you_member')}</div>
                <div className="login-page-sub">{t('then_confirm_email')}</div>
                <div className="login-page-input">
                    <input type="email" value={email} onChange={(ev) => setEmail(ev.target.value)} placeholder={t('your_email')} />
                    <input type="password" value={password} onChange={(ev) => setPassword(ev.target.value)} placeholder={t('your_password')} />
                </div>
                <div className="login-page-button">
                    {loading ? (
                        <button>
                            <FaSpinner />
                        </button>
                    ) : (
                        <button onClick={onLogin}>{t('login')}</button>
                    )}
                </div>
                <Link to="/forgotpassword" className="login-page-forgot-pwd">
                    {t('forgot_password')}
                </Link>
                {errorMsg && <div className="login-page-error">{errorMsg ? t(errorMsg) : ''}</div>}
                <Footer />
            </div>
        </>
    );
};

export default LoginWithEmailAndPassword;
