import React from 'react';
import classNames from 'classnames';
import videojs from 'video.js';

export default function VideoJSPlayer({ id, src, poster, className, onEnd, onPlay, onError, onDispose }) {
    const videoRef = React.useRef(null);
    const vjsRef = React.useRef(null);

    React.useEffect(() => {
        if (!vjsRef.current) {
            const videoEl = videoRef.current;
            const vjsPlayer = (vjsRef.current = videojs(
                videoEl,
                {
                    id: id,
                    sources: [
                        {
                            src: src,
                            type: 'video/mp4',
                        },
                    ],
                    responsive: true,
                    controls: true,
                    controlBar: {
                        captionsButton: false,
                        subtitlesButton: false,
                    },
                    poster: poster,
                    muted: false,
                    autoplay: true,
                },
                () => {
                    vjsRef.current = vjsPlayer;
                    vjsPlayer.on('play', onPlay);
                    vjsPlayer.on('dispose', onDispose(vjsPlayer.remainingTime()));
                    vjsPlayer.on('error', onError(vjsPlayer.error().message));
                    vjsPlayer.on('waiting', () => {});
                    vjsPlayer.on('ended', onEnd);
                }
            ));
            if (vjsPlayer) {
                vjsPlayer.landscapeFullscreen({
                    fullscreen: {
                        enterOnRotate: true,
                        exitOnRotate: true,
                        alwaysInLandscapeMode: true,
                        iOS: true,
                    },
                });
            }
        }
        return () => {
            if (vjsRef.current) {
                vjsRef.current.dispose();
                vjsRef.current = null;
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src, poster, id]);

    return (
        <div className="video-player-inner">
            <div data-vjs-player>
                <video
                    ref={videoRef}
                    playsInline
                    className={classNames('video-js', 'vjs-big-play-centered', 'vjs-theme-fantasy', {
                        [className]: className !== undefined,
                    })}
                    data-setup='{"fluid": true}'
                    crossOrigin="anonymous"
                />
            </div>
        </div>
    );
}
