import React from 'react';
import { useKeenSlider } from 'keen-slider/react';
import useGetPageConfig from '../../hooks/useGetPageConfig';

const MutationPlugin = (slider) => {
    const observer = new MutationObserver(function (mutations) {
        mutations.forEach(function () {
            slider.update();
        });
    });
    const config = { childList: true };

    slider.on('created', () => {
        observer.observe(slider.container, config);
    });
    slider.on('destroyed', () => {
        observer.disconnect();
    });
};

export default function Slider({ slides }) {
    const { rtl } = useGetPageConfig();
    const [sliderRef] = useKeenSlider(
        {
            mode: 'free-snap',
            breakpoints: {
                '(min-width: 500px)': {
                    slides: { perView: 2.5, spacing: 10 },
                },
                '(min-width: 768px)': {
                    slides: { perView: 3.5, spacing: 10 },
                },
                '(min-width: 1024px)': {
                    slides: { perView: 4.5, spacing: 10 },
                },
                '(min-width: 1280px)': {
                    slides: { perView: 5.5, spacing: 10 },
                },
            },
            rtl: rtl,
            slides: {
                spacing: 10,
                perView: 1.5,
            },
        },
        [MutationPlugin]
    );

    return (
        <div className="keen-slider" ref={sliderRef}>
            {slides.map((item, idx) => (
                <div key={idx} className="keen-slider__slide slider-item">
                    {item}
                </div>
            ))}
        </div>
    );
}
